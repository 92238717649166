<template id="product">
  <!--Breadcrumbs -->
  <breadcrumb-product :param="breadcrumb_param" />
  
  <div id="maincontent_container">
    
    

    <!-- Right Side: main content -->
    <div class="float-end col-md-9 ps-1 mb-5">
      <spinner v-if="loading" />

      <!-- Pagination-->
      <pagination
        v-if="!loading"
        :currentPage="currentPage"
        :pageCount="pageCount"
        :totalResult="totalResult"
        :urlParams="urlParams"
        :sortBy="sortBy"
        :filter_param_string="filter_param_string"
        :fParams="fParams"
      />

      <!-- Filters -->
      <div v-if="isAuthenticated" class="clearfix mb-2">
        
        <!-- filter 1: in-stock -->
        <div class="form-check float-start pe-5">
          <input
              name="filter"
              class="form-check-input"
              type="checkbox"
              value="instock"
              id="instock"
              :checked = "checkInstock"
              @change="handlePicked($event)"
          />
          <label class="form-check-label" for="inStock">
              In Stock
          </label>
        </div>

        <!-- filter 2: new Arrivals -->
        <!-- <div class="form-check float-start pe-5">
          <input
              name="filter"
              class="form-check-input"
              type="checkbox"
              value="newitm"
              id="newitm"
              :checked = "checkNewitm"
              @change="handlePicked($event)"
          />
          <label class="form-check-label" for="newitm">
              New Item
          </label>
        </div> -->

        <!-- filter 3: instant rebate -->
        <div class="form-check float-start pe-5">
          <input
              name="filter"
              class="form-check-input"
              type="checkbox"
              value="rebinst"
              id="rebinst"
              :checked = "checkRebinst"
              @change="handlePicked($event)"
          />
          <label class="form-check-label" for="rebinst">
              Instant Rebate
          </label>
        </div>

        <!-- filter 4: mailin rebate -->
        <div class="form-check float-start pe-5">
          <input
              name="filter"
              class="form-check-input"
              type="checkbox"
              value="rebmail"
              id="rebmail"
              :checked = "checkRebmail"
              @change="handlePicked($event)"
          />
          <label class="form-check-label" for="rebmail">
              Mail-In Rebate
          </label>
        </div>


        <!-- Fitler 5: sort by -->
        <div class="float-end">
            <div class="row">
                <label class="col-sm-4 col-form-label">Sort By:</label>
                <div class="col-sm-8">
                    <select v-model="sortBy" class="form-select"  id="sortBy" name="sortBy" @change="onChangeSort($event)">
                      <option value=""></option>  
                      <option value="-item__balance">Highest Inventory</option>                     
                      <option value="-item__price">Highest Price</option>
                      <option value="item__price">Lowest Price</option>
                  </select>
                </div>
            </div>                     
        </div>
      </div>
      <!-- End of Filters -->

      <hr>

      <!-- Product List -->
      <div class="mb-5">
        <div v-for="item in items" :key="item" class="row">
          
          <!-- product image-->
          <div class="col-md-1 div_img_thumbmail">
            <!-- <router-link
                        :to="{
                          name: 'ProductDetails',
                          params: { itemno: encodeURIComponent(item.itmno) },
                        }"
                        ><img :src="item.thumbnail.url" alt="" />
            </router-link>   -->
            <router-link :to="'/products/details/?itmno=' + encodeURIComponent(item.itmno)">
            <img :src="item.thumbnail.url" class="thumbnail_image" alt="" />
            </router-link>  
          </div>
          
          <!-- product description -->
          <div :class="isAuthenticated? 'col-md-8':'col-md-11'" class="row">
            
            <div class="clearfix">
              <!-- brand logo -->
              <div class="float-start me-5">
                <router-link :to="'/products/?mfid='+item.mfid+'&ordering=-item__balance'"><img v-if="item.brand_logo.url" :src="item.brand_logo.url" :width="80">  </router-link>
              </div>
              <!-- special tags -->
              <div v-if="isAuthenticated">
                <!-- {{item}} -->
                <!-- <div v-if="item.newitm == true" class="float-start ms-5 mt-3">
                  <span class="badge bg-danger p-2">New Item</span>
                </div> -->
                <div v-if="item.rebitmno!=none && item.rebstart<=today && item.rebstop>=today" class="float-start ms-5 mt-3">
                  <span class="badge bg-success p-2">Instant Rebate</span>
                </div>
                <div v-if="item.mail_in_rebate.price!=None && item.mail_in_rebate.start_date<=today && item.mail_in_rebate.end_date>=today" class="float-start ms-5 mt-3">
                  <span class="badge bg-secondary p-2">Mail-In Rebate</span>
                </div>
                <div v-if="item.hotitm == true" class="float-start ms-5 mt-3">
                  <span class="badge bg-info p-2">Hot Items</span>
                </div>
              </div>
            </div>
            <!-- title -->
            <div><!--<router-link
                        :to="{
                          name: 'ProductDetails',
                          params: { itemno: item.itmno },
                        }"
                        >{{ item.spec_short }}</router-link
                      >-->
                  <router-link :to="'/products/details/?itmno='+encodeURIComponent(item.itmno)">{{ item.spec_short }}</router-link>
            </div>
            <!-- Item#, List#, Mfr#... -->
            <div class="small_font">
                      List #: <strong>{{ item.lstno }}</strong> | SKU #:
                      <strong>{{ item.itmno }}</strong> | Mfr #:
                      <strong>{{ item.mfgpartno }}</strong> | UPC:
                      <strong>{{ item.barcode }}</strong>
            </div>
            <!-- special order tag -->
            <div v-if="isAuthenticated">
              <div v-if="item.specorder == true" class="mt-2">
                <span class="badge bg-warning p-2">Special-Order: Non-cancellable</span>
              </div>
            </div>
          </div>
          
          <!-- stock -->
          <div v-if="isAuthenticated" class="col-md-1">
            <router-link :to="{name: 'ProductDetails',params: { itemno: item.itmno }}">
            <div v-if="item.balance <= 0" class="col">
              <i class="bi bi-chat-right-dots icon"></i> check availability
            </div>
            <div v-else class="col link-success">
              <strong>In Stock</strong> 
            </div>
            </router-link>
          </div>

          <!-- product price -->
          <div v-if="isAuthenticated" class="col-md-1">
            <div class="row fs-5"><span>{{formatPrice(item.price)}}</span></div>
            <div v-if="item.rebamount !== 0">
              <div class="row text-danger ir_price"><span>{{formatPrice(item.rebamount)}} (Instant Rebate)</span></div>
              <div class="row fs-5 fw-bold"><span>{{formatPrice(item.price+item.rebamount)}}</span></div>
            </div>   
            
          </div>
          
          <hr class="mt-4">
        </div>
      </div>
      <!-- End of Product List-->

      <!-- Pagination at the bottom -->
      <pagination
        v-if="!loading"
        :currentPage="currentPage"
        :pageCount="pageCount"
        :totalResult="totalResult"
        :urlParams="urlParams"
        :sortBy="sortBy"
        :filter_param_string="filter_param_string"
        :fParams="fParams"
      />

      <p v-if="!loading" class="link-warning">
            Some brands may not be eligiable for international purchase and can
            only be sold to USA Domestic customers only. Please verify with your
            sales rep for future information
      </p>
    </div>
    <!-- End of Right Side: main content -->

    <!-- Left Side: category list, brand list and ads -->
    <div class="float-start col-md-3 pe-5 mb-5 product_submenu_div">
      <submenuProduct
            :catid="catid"
            :mfid="mfid"
            :brands="brands"
            :urlParams="urlParams"
            :fParams="fParams"
            @searchFilter="searchFilter"
          />
      <ads-vertical></ads-vertical>
    </div>
    <!-- End of Left Side: category list and brand list -->

  </div>

  
</template>

<script>
import submenuProduct from "../../components/nav/submenuProduct.vue";
import Spinner from "../../components/Spinner.vue";
import Pagination from "../../components/Pagination.vue";
import AdsVertical from "../../components/ads/AdsVertical.vue";
import BreadcrumbProduct from "../../components/BreadcrumbProduct.vue";

import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { getAPI, getAuthAPI } from "../../utils/axios-api";
import { formatPrice } from '../../utils/common';
import { parseFilterAtrList } from '../../utils/common';
import moment from "moment";
import $ from 'jquery'

export default {
  name: "Products",
  components: { submenuProduct, Spinner, Pagination,AdsVertical, BreadcrumbProduct },
  //  props: [ 'page' ],
  setup() {
    const brands = ref(null);
    const items = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const pageCount = ref(null);
    const currentPage = ref(null);
    const itemsPerPage = ref(100);
    const totalResult = ref(0);
    const urlParams = ref("");
    const fParams = ref("")
    const sortBy = ref("")
    const filter_param = ref({})
    var checkInstock = ref(false)
    var checkRebinst = ref(false)
    var checkRebmail = ref(false)
    var checkNewitm = ref(false)
    const current = new Date();
    const today = moment(current).format("YYYY-MM-DD")

    let price = 0;

    //get url parameters
    const route = useRoute();
    const router = useRouter();

    //params for breadcrumb
    const breadcrumb_param = ref([])

    //filter flag and filter_arg variable for search post api
    var filter = ""
    var filter_arg = {
      catid: "",
      mfid: "",
      rebinst: false,
      rebmail: false,
      instock: false,
      balance_desc: false,
      price_desc: false,
      atrvals: []
    }

    if (typeof route.query.page === "undefined") {
      currentPage.value = 1;
    } else {
      currentPage.value = parseInt(route.query.page);
      if (currentPage.value < 1) {
        currentPage.value = 1;
      }
    }
    var offset = (currentPage.value - 1) * itemsPerPage.value;
    urlParams.value = urlParams.value + "&offset=" + offset

    const catid = ref("");
    var catid_param = "";
    if (typeof route.query.catid != "undefined" && route.query.catid != "") {
      catid.value = route.query.catid;
      catid_param = "&catid=" + route.query.catid;
      urlParams.value = urlParams.value + catid_param
      breadcrumb_param.value.push({"type": "catid", "value": catid.value})
    }

    var mfid = ref("");
    var mfid_param = "";
    if (typeof route.query.mfid != "undefined" && route.query.mfid != "") {
      mfid.value = route.query.mfid;
      mfid_param = "&mfid=" + route.query.mfid;
      urlParams.value = urlParams.value + mfid_param
      breadcrumb_param.value.push({"type": "mfid", "value": mfid.value})
    }

    var itmno = "";
    var itmno_param = "";
    if (typeof route.query.itmno != "undefined" && route.query.itmno != "") {
      itmno = route.query.itmno;
      itmno_param = "&itmno=" + encodeURIComponent(route.query.itmno);
      urlParams.value = urlParams.value + itmno_param
      breadcrumb_param.value.push({"type": "itmno", "value": itmno})
    }

    var search = "";
    var search_param = "";
    if (typeof route.query.search != "undefined" && route.query.search != "") {
      search = route.query.search;
      //search string is encoded from navbar.vue already
      search_param = "&search=" + route.query.search
      urlParams.value = urlParams.value + search_param
      breadcrumb_param.value.push({"type": "keyword", "value": search})
    }
    

    var ordering = "";
    var ordering_param = "";
    if (typeof route.query.ordering != "undefined" && route.query.ordering != "") {
      ordering = route.query.ordering;
      ordering_param = "&ordering=" + route.query.ordering;
      sortBy.value = route.query.ordering
      if (sortBy.value == '-item__balance')
        filter_arg["balance_desc"] = true
      else if (sortBy.value == '-item__price')
        filter_arg["price_desc"] = true
    }
    // filter
    if (typeof route.query.instock != "undefined" && route.query.instock != "") {
      filter_param.value["instock"] = "&instock=true"
      checkInstock.value = true
      filter_arg["instock"] = true
    }
    if (typeof route.query.rebinst != "undefined" && route.query.rebinst != "") {
      filter_param.value["rebinst"] = "&rebinst=true"
      checkRebinst.value = true
      filter_arg["rebinst"] = true
    }
    if(typeof route.query.rebmail != "undefined" && route.query.rebmail != ""){
      filter_param.value["rebmail"] = "&rebmail=true"
      checkRebmail.value = true
      filter_arg["rebmail"] = true
    }
    if(typeof route.query.newitm != "undefined" && route.query.newitm != ""){
      filter_param.value["newitm"] = "&newitm=true"
      checkNewitm.value = true
    }
    //get product list
    var filter_param_string = ""
    if (filter_param.value) {
      for (var key in filter_param.value) {
        filter_param_string = filter_param_string + filter_param.value[key];
      }
    }

    //filter from left side: fParams
    if (typeof route.query.filter != "undefined" && route.query.filter != "") {
      fParams.value = fParams.value + "&filter=" + route.query.filter
      filter = route.query.filter
    }
    if (typeof route.query.filter1c != "undefined" && route.query.filter1c != "") {
      fParams.value = fParams.value + "&filter1c=" + route.query.filter1c
      filter_arg["catid"] = route.query.filter1c
    }
    if (typeof route.query.filter2m != "undefined" && route.query.filter2m != "") {
      fParams.value = fParams.value + "&filter2m=" + route.query.filter2m
      filter_arg["mfid"] = route.query.filter2m
    }
    if (typeof route.query.filter3a != "undefined" && route.query.filter3a != "") {
      fParams.value = fParams.value + "&filter3a=" + route.query.filter3a
      filter_arg["atrvals"] = parseFilterAtrList(fParams.value)
      // try {
      //   var atr_list = route.query.filter3a.split("--")
      //   var atr, atrid, value_list
      //   for (var i = 0; i < atr_list.length; i++) {
      //     atr = []
      //     value_list = []
      //     atr = atr_list[i].split(":")
      //     if (Array.isArray(atr)) {
      //       atrid = atr[0]
      //       value_list = atr[1].split(",")
      //       filter_arg["atrvals"].push({"atrid": atrid, "valid": value_list})
      //     }
          
      //   }
      // }
      // catch {
      //   //pass
      // }
    }
    
    
    //get user's access token
    const store = useStore();
    let isAuthenticated = store.getters.isAuthenticated;
    var productsAPI;
    if (isAuthenticated) {
      productsAPI = getAuthAPI;
    } else {
      productsAPI = getAPI;
    }

    var url
    if (filter == "") {//when no fitler, using get method
      url = "/product/items/?limit=" + itemsPerPage.value + urlParams.value + ordering_param + filter_param_string + fParams.value;
      productsAPI
      .get(url)
      .then((response) => {
        items.value = response.data.results;
        brands.value = response.data.brands;
        totalResult.value = response.data.count;
        pageCount.value = Math.ceil(response.data.count / itemsPerPage.value);
        loading.value = false;
      })
      .catch((err) => {
        loading.value = false;
        error.value = "Something is wrong with the server: " + err;
      });
    }
    else {//when filter == y, using post method
      url = "/product/filter/list/"
      productsAPI
      .post(url, filter_arg)
      .then(response => {
        items.value = response.data.results;
        brands.value = response.data.brands;
        totalResult.value = response.data.count;
        pageCount.value = Math.ceil(response.data.count / itemsPerPage.value);
        loading.value = false;
      })
      .catch(err => {
        //pass
        loading.value = false;
        error.value = "Something is wrong with the server: " + err;
      })
    }
      

    const formatInventoryNumber = (value) => {
      let num = Number(value).toLocaleString();
      return num;
    };

    const inquiry = (event) => {
      router.push({ name: "Login" });
    };


    //   sortby function
    function onChangeSort(event){
      sortBy.value = event.target.value
      var url = "/products/?page=1"  + urlParams.value + "&ordering="+sortBy.value + filter_param_string + fParams.value;
      router.push( url );
    }

    // filter function
    const handlePicked = (event) => {
      if(event.target.checked==true){
        filter_param.value[event.target.value] =  "&"+ event.target.value + "=true"
      }
      else{
        delete(filter_param.value[event.target.value])
      }
      filter_param_string = ""
      if (filter_param.value) {
        for (var key in filter_param.value) {
          filter_param_string = filter_param_string + filter_param.value[key];
        }
      }
      var url2 = "/products/?page=1"  + urlParams.value + "&ordering="+sortBy.value + filter_param_string + fParams.value;
      router.push( url2 );
    }

    //search Filter: handle the search filter values passed from submenuProduct component and send search request 
    const searchFilter = (arg) => {
      
      var filter3 = ""
      for (var i = 0; i<arg["atrvals"].length; i++) {
        if (!!filter3) filter3 = filter3 + "--"
        filter3 = filter3 + arg["atrvals"][i]["atrid"] + ":" + arg["atrvals"][i]["valid"].toString()
      }
      fParams.value = "&filter=y&filter1c=" + arg["catid"] + "&filter2m=" + arg["mfid"] + "&filter3a=" + filter3

      var url = "/products/?page=1"  + urlParams.value + "&ordering="+sortBy.value + filter_param_string + fParams.value
      router.push( url );
    }
    

    return {
      brands,
      items,
      loading,
      error,
      pageCount,
      currentPage,
      urlParams,
      isAuthenticated,
      totalResult,
      formatPrice,
      inquiry,
      formatInventoryNumber,
      breadcrumb_param,
      catid,
      mfid,
      onChangeSort,
      sortBy,
      handlePicked,
      checkInstock,
      checkRebinst,
      checkRebmail,
      checkNewitm,
      filter_param_string,
      fParams,
      today,
      searchFilter
    };
  },
};
</script>

<style scoped>
a {
  color: black;
  text-decoration: none;
}
a:hover{
  color:cornflowerblue;
  text-decoration: underline;
}
#inquiryBtn {
  background-color: blue;
}
.icon {
  padding-left:10px;
  font-size:1.5rem;
  color:black;
}
.product_submenu_div {
  max-width:400px;
}
.div_img_thumbmail {
  min-width:100px;
}
.ir_price { 
  white-space: nowrap;
}
.thumbnail_image {max-width: 75px;}

/* Mobile fix */
@media (max-width: 767px) {
  .pagination_sec {
    display: table;
  }
  #pageNumber {
    float: right !important
  }
  .float-end {
    float: none !important;
  }
}
</style>
