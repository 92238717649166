<template>
  <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" id="breadcrumb_container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/products">Products</a></li>
      <li v-for="item in breadcrumb_list" class="breadcrumb-item active" aria-current="page">
        <router-link v-if="item.type =='catid'" :to="'/products/?catid='+item.catid+'&ordering=-item__balance'">{{item.name}}</router-link>
        <router-link v-if="item.type=='mfid'" :to="'/products/?mfid='+item.mfid">{{item.name}}</router-link>
        <span v-if="item.type=='keyword'">{{item.name}}</span>
        <span v-if="item.type=='itmno'">{{item.itmno}}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { ref } from 'vue'
import { getAPI } from '../utils/axios-api'

export default {
  props: [ 'param'],
  setup(props) {
    //breadcrumb list
    const breadcrumb_list = ref([])

    if (!!props.param) {
      var url = "/product/breadcrumbs/"
      getAPI
        .post(url, props.param)
        .then((response) => {
          breadcrumb_list.value = response.data["results"]
        })
        .catch((err) => {
          //error
        })
    }
    
    
    return { breadcrumb_list }
  }
}
</script>

<style scoped>
.breadcrumb a:link {text-decoration: none;}
a{
  color:#555;
}

</style>


